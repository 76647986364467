var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.statusInfor)?_c('business-status',{attrs:{"statusInfor":_vm.statusInfor,"busHandlingId":_vm.busHandlingId}}):_vm._e(),_c('div',{staticClass:"real-infor"},[_c('van-form',{ref:"realInfo"},[_c('van-field',{attrs:{"label":_vm.labelName,"name":"contacts","required":"","input-align":_vm.realAlign,"placeholder":`请填写${_vm.labelName}`,"readonly":_vm.canEdit,"rules":[
            { required: true },
            { pattern: /^[\u4e00-\u9fa5]{2,30}$/, message: '必须是长度为2到30的字符串！' }
          ]},model:{value:(_vm.realName.contacts),callback:function ($$v) {_vm.$set(_vm.realName, "contacts", $$v)},expression:"realName.contacts"}}),_c('van-field',{attrs:{"label":"手机号","name":"telephone","required":"","input-align":_vm.realAlign,"placeholder":`请填写${_vm.labelName}手机号`,"readonly":_vm.canEdit,"rules":[
            { required: true },
            {
              pattern: /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,7,8])|(18[0-9])|(19[8,9]))\d{8}$/,
              message: '请输入正确手机号码'
            }
          ]},model:{value:(_vm.realName.telephone),callback:function ($$v) {_vm.$set(_vm.realName, "telephone", $$v)},expression:"realName.telephone"}})],1)],1)],1),_c('div',{staticClass:"vant-parser"},[(_vm.formConf)?_c('parser',{key:_vm.key2,ref:"parser",attrs:{"form-conf":_vm.formConf},on:{"submit":_vm.sumbitForm1,"failed":_vm.failedForm}}):_vm._e()],1),(this.businessState === 'see' && _vm.stateBoolean)?_c('div',{staticClass:"footer"},[_c('div',{staticClass:"btns"},[_c('van-button',{staticStyle:{"margin-right":"10px"},attrs:{"plain":"","type":"info"},on:{"click":_vm.showCancelReport}},[_vm._v(" 取消办理 ")]),_c('van-button',{attrs:{"plain":"","type":"info"},on:{"click":_vm.updateInfo}},[_vm._v("修改信息")])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }